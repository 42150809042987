import React, { useRef, useState } from "react";
import "./layout.scss";
import UserDetailsCanvas from "./UserDetailsCanvas";
import LayoutHeader from "./LayoutHeader";
import SideMenu from "./SideMenu";
import MobileNavbar from "../../components/mobile/nav/MobileNavbar";

const Layout = ({ children }) => {
  const [showUserDetailsCanvas, setShowUserDetailsCanvas] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <div className="h-screen flex items-stretch bg-gray-100">
        {/* mobile navbar */}
        {/* side menu */}
        <SideMenu />
        <main className="flex-1 flex flex-col overflow-auto">
          <MobileNavbar
            showUserDetailsCanvas={showUserDetailsCanvas}
            setShowUserDetailsCanvas={setShowUserDetailsCanvas}
          />
          {/* header */}
          <LayoutHeader
            setShowUserDetailsCanvas={setShowUserDetailsCanvas}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
          />

          {/* content */}
          <div
            className="flex-1  py-7 overflow-auto"
            onClick={() => setShowDropdown(false)}
          >
            <div className="px-6 w-full h-full">{children}</div>
          </div>

          {/* footer */}
          <footer className="bg-gray-50 p-2 text-center flex items-center justify-center text-xs xl:text-sm">
            Designed & Developed by ZigTech
          </footer>
        </main>
      </div>

      <UserDetailsCanvas
        isOpen={showUserDetailsCanvas}
        setIsOpen={() => setShowUserDetailsCanvas(false)}
      />

      {/* <UserDetailsCanvas
        isOpen={showUserDetailsCanvas}
        setIsOpen={() => setShowUserDetailsCanvas(false)}
      /> */}
    </>
  );
};

export default Layout;
