import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "user",
  initialState: {
    update: Date.now(),
    auth: null,
    location: null,
    permission: null,
    token: localStorage.getItem("token"),
    pending: false,
    error: false,
    errorMsg: "",
    logged: "",
    clientData: {}
  },

  reducers: {
    loginRequest: (state) => {
      state.pending = true;
    },
    CancelPending: (state) => {
      state.pending = false;
    },
    registerRequest: (state) => {
      state.pending = true;
    },
    loginSuccess: (state, action) => {
      state.pending = false;
      state.auth = action.payload;
      state.error = false;
      state.errorMsg = "";
      // localStorage.setItem("token", state.token);
    },
    registerSuccess: (state, action) => {
      state.pending = false;
      state.user = action.payload;
      state.error = false;
      state.errorMsg = "";
    },
    loginError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
      localStorage.removeItem("token");
    },

    loginErrorVerify: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
      localStorage.removeItem("token");
      window.location.href = "/";
    },
    registerError: (state, action) => {
      state.error = true;
      state.pending = false;
      state.errorMsg = action.payload;
    },
    updateState: (state, action) => {
      state.update = Date.now();
    },
    logout: (state) => {
      state.error = false;
      state.auth = null;
      localStorage.clear();
      window.location.href = "/";
      state.logged = false;
    },
    setClienData: (state, action) => {
      state.clientData = action.payload
    }
  },
});

export const {
  loginRequest,
  registerRequest,
  loginSuccess,
  CancelPending,
  registerSuccess,
  registerError,
  loginError,
  loginErrorVerify,
  updateState,
  logout,
  setClienData
} = authSlice.actions;
export default authSlice.reducer;
