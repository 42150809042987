import React, { useState } from "react";
import { Badge, ListGroup, Offcanvas } from "react-bootstrap";
import { BorderColor, Logout } from "@mui/icons-material";
import EditUserDetailsForm from "./EditUserDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import ChangeUserPasswordForm from "./ChangeUserPasswordForm";
import { Fade } from "react-awesome-reveal";
import { logout } from "../../redux/Slices/Auth";
import { FaX } from "react-icons/fa6";

const UserDetailsCanvas = ({ isOpen, setIsOpen }) => {
  const { clientData } = useSelector((state) => state.auth);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const dispatch = useDispatch();
  return (
    <Offcanvas
      placement="end"
      show={isOpen}
      onHide={() => {
        setShowEditForm(false);
        setShowChangePasswordForm(false);
        setIsOpen();
      }}
    >
      <Offcanvas.Header className="flex items-center justify-between p-2">
        <button
          onClick={setIsOpen}
          className="xl:hidden outline-none border-none bg-transparent p-2"
        >
          <FaX className="text-xl" />
        </button>
        <div className="d-flex justify-content-end align-items-center w-full">
          {!showEditForm && !showChangePasswordForm ? (
            <button
              className="btn btn-sm btn-primary mr-2"
              onClick={() => setShowChangePasswordForm(true)}
            >
              Change Password
            </button>
          ) : (
            !showEditForm &&
            showChangePasswordForm && (
              <button
                onClick={() => setShowChangePasswordForm(false)}
                className="btn btn-sm btn-dark"
              >
                Cancel
              </button>
            )
          )}

          {!showEditForm && !showChangePasswordForm ? (
            <BorderColor
              onClick={() => setShowEditForm(true)}
              sx={{
                marginLeft: 2,
                cursor: "pointer",
              }}
            />
          ) : (
            showEditForm &&
            !showChangePasswordForm && (
              <button
                onClick={() => setShowEditForm(false)}
                className="btn btn-sm btn-dark"
              >
                Cancel
              </button>
            )
          )}
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!showChangePasswordForm && !showEditForm && (
          <Fade>
            <ListGroup variant="flush" className="my-2">
              <ListGroup.Item>
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <div
                    className="font-bold bg-green-600 text-white flex items-center justify-center rounded-full text-2xl"
                    style={{
                      width: 75,
                      height: 75,
                    }}
                  >
                    {(clientData?.firstName ? clientData?.firstName[0] : "") +
                      (clientData?.lastName ? clientData?.lastName[0] : "")}
                  </div>
                </div>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Full Name:</b> {clientData.firstName}{" "}
                {clientData.middleName || ""} {clientData.lastName}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Email:</b> {clientData.email}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Mobile:</b> {clientData.phone}
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Status:</b>{" "}
                <Badge
                  bg={clientData.status === "active" ? "success" : "danger"}
                  className="text-uppercase"
                >
                  {clientData.status}
                </Badge>
              </ListGroup.Item>
              <ListGroup.Item>
                <b>Institution:</b> {clientData.institution}
              </ListGroup.Item>
            </ListGroup>
          </Fade>
        )}

        {/* Edit User Details */}
        {showEditForm && (
          <EditUserDetailsForm setShowEditForm={setShowEditForm} />
        )}
        {showChangePasswordForm && (
          <ChangeUserPasswordForm
            setShowChangePasswordForm={setShowChangePasswordForm}
          />
        )}
      </Offcanvas.Body>
      <div className="p-2">
        <button
          onClick={() => {
            localStorage.clear();
            dispatch(logout());
          }}
          className="btn btn-block w-100 btn-danger"
        >
          Logout{" "}
          <Logout
            sx={{
              marginLeft: 2,
              cursor: "pointer",
            }}
          />
        </button>
      </div>
    </Offcanvas>
  );
};

export default UserDetailsCanvas;
