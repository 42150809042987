import http from "../http-business";
import { toast } from "react-toastify";
import {
  CancelPending,
  loginError,
  loginErrorVerify,
  loginRequest,
  loginSuccess,
  registerError,
  registerRequest,
  registerSuccess,
} from "../redux/Slices/Auth";
import { useState } from "react";

//login user
export const loginBussiness = async (user, dispatch, navigate) => {
  dispatch(loginRequest());
  const data = await http
    .post("/bussinessClient/login", user)
    .then(async (response) => {
      localStorage.setItem("businessToken", response.data.token);
      const verified = await verifyBussiness(dispatch);
      if (verified.data.success) {
        navigate("/dashboard-bussiness");
        dispatch(loginSuccess(verified.data.bussinessClientData));
      }
    })
    .catch((error) => {
      toast.error(error.response?.data?.message);
      // dispatch(loginError(error));
    });

  return data;
};

//verify user
export const verifyBussiness = async (dispatch) => {
  if (!localStorage.getItem("businessToken")) {
    window.location = "/";
  }
  try {
    const response = await http.get("/bussinessClient/verify");
    dispatch(loginSuccess(response.data.bussinessClientData));
    return response;
  } catch (error) {
    return error.response.data;
  }
};

//register user
export const signinBussiness = (submitted, dispatch, navigate) => {
  dispatch(registerRequest());
  const authToken = localStorage.getItem("businessToken");
  http
    .post("/bussinessClient/register", submitted)
    .then((res) => {
      if (res.data.success) {
        toast.success(res.data.message);
        dispatch(registerSuccess(res.data.data));
        navigate("/login-bussiness");
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      dispatch(registerError());
    });
};

// Change business client password
export const changePassword = async (values) => {
  try {
    const { data } = await http.post(
      "/bussinessClient/forgotBusinessClientPassword",
      values
    );

    return {
      ...data,
      success: true,
      message: data.message || "Password reset toke sent to your email",
    };
  } catch (err) {
    return {
      ...err?.response,
      success: false,
      message:
        err.response?.data?.message || "Cannot send password reset token",
    };
  }
};

// Resend business client password reset link
export const resentPasswordResetLink = async (values) => {
  try {
    const { data } = await http.post(
      "/bussinessClient/resendEmailVerification",
      values
    );

    return {
      ...data,
      success: true,
      message:
        data.message || "Password reset link has been resent to your email",
    };
  } catch (err) {
    return {
      ...err?.response,
      success: false,
      message:
        err.response?.data?.message || "Cannot resend password reset link",
    };
  }
};

export const verifyConfirmationCode = async (token) => {
  try {
    const { data } = await http.get(
      `/bussinessClient/verifyBusinessClientToken/${token.code}`
    );
    return {
      ...data,
      success: true,
      message: data.message || "Token successfully verified",
    };
  } catch (err) {
    return {
      success: false,
      message: err.response?.data?.message || "Cannot verify token try",
    };
  }
};

export const resetPassword = async (values) => {
  try {
    const { data } = await http.put(
      `/bussinessClient/updateBusinessClientPassword`,
      values
    );
    return {
      ...data,
      success: true,
      message: data.message || "Password successfully changed",
    };
  } catch (err) {
    return {
      success: false,
      message: err.response?.data?.message || "Cannot change password",
    };
  }
};

export const changeClientPassword = async ({ email }) => {
  try {
    const { data } = await http.post("/clientAuth/forgotClientPassword", {
      email,
    });

    return {
      ...data,
      success: true,
      message: data.message || "Password reset toke sent to your email",
    };
  } catch (err) {
    return {
      ...err?.response,
      success: false,
      message:
        err.response?.data?.message || "Cannot send password reset token",
    };
  }
};

export const verifyClientConfirmationCode = async (token) => {
  try {
    const { data } = await http.get(
      `/clientAuth/verifyClientToken/${token.code}`
    );
    return {
      ...data,
      success: true,
      message: data.message || "Token successfully verified",
    };
  } catch (err) {
    return {
      success: false,
      message: err.response?.data?.message || "Cannot verify token try",
    };
  }
};

export const resetClientPassword = async (values) => {
  try {
    const { data } = await http.put(
      `/clientAuth/updateForgottenClientPassword`,
      values
    );
    return {
      ...data,
      success: true,
      message: data.message || "Password successfully changed",
    };
  } catch (err) {
    return {
      success: false,
      message: err.response?.data?.message || "Cannot change password",
    };
  }
};

export const getSingleBusiness = async (business_id) => {
  const data = await http
    .get(`/bussinessRegistration/${business_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const edittBusiness = async (business_id, submitted) => {
  const authToken = localStorage.getItem("businessToken");
  const data = await http
    .put(`/bussinessRegistration/${business_id}`, submitted, {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

//search business
export const searchBusiness = async (searched) => {
  const data = await http
    .post("/bussinessRegistration/searchBusinessForClient", searched)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

//edit profile
export const edittProfile = async (formData) => {
  try {
    const { data } = await http.put(
      `/bussinessClient/updateBusinessClientProfile`,
      formData
    );
    return {
      ...data,
      success: true,
      message: "Profile details updated successfully",
    };
  } catch (err) {
    return {
      ...err?.response,
      success: false,
      message: err?.response?.data?.message || "Cannot update profile details",
    };
  }
};

//get all tariff
export const getAllTariff = async () => {
  const data = await http
    .get("/tarif")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

//create business
// export const createBusiness = async (submitted) => {
//   const authToken = localStorage.getItem("businessToken");
//   const data = await http
//     .post("/bussinessRegistration", submitted, {
//       headers: {
//         "Content-Type": "multipart/form-data",
//         Authorization: `Bearer ${authToken}`,
//       },
//     })
//     .then((response) => {
//       console.log("success response:",response)
//       return response.data;
//     })
//     .catch((err) => {
//       toast.error(err)
//       console.log(err)
//       return err.response;
//     });

//   return data;
// };
export const createBusiness = async (submitted) => {
  const authToken = localStorage.getItem("businessToken");
  try {
    const response = await http.post("/bussinessRegistration", submitted, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.data;
  } catch (err) {
    console.log(err);

    if (err.response || err.response.data || err.response.data.message) {
      await toast.error(err.response.data.message);
    } else {
      await toast.error("An error occurred");
    }

    return err.response;
  }
};

//get tariffs
export const getTariffs = async () => {
  const data = await http
    .get("/tarif")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

//upload payment
export const uploadPaymentFile = async (file) => {
  const authToken = localStorage.getItem("businessToken");
  const data = await http
    .post("/certificate/uploadPaymentDocument", file, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((response) => {
      window.location.reload();
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });

  return data;
};

//read business document
export const readBusinessDoc = async (doc_id) => {
  const data = http
    .get(`/bussinessRegistration/readBusinessDocument/${doc_id}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch((error) => toast.error(error.message));

  return data;
};

//read payment receipt
export const readPaymentReceiptDoc = async (doc_id, client_id) => {
  const data = http
    .post(
      `/certificate/readCertificate/paymentReciept/${doc_id}`,
      {
        clientId: client_id,
      },
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);

      return url;
    })
    .catch((error) => toast.error(error.message));

  return data;
};

// Get business messages

export const getBusinessMessages = async (setLoading, setMessages) => {
  const authToken = localStorage.getItem("businessToken");
  const data = await http
    .get("/businessMessage/bMessage", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    })
    .then((res) => {
      setLoading(false);
      setMessages(res.data.data);
    })
    .catch((e) => {
      setLoading(false);
    });

  return data;
};

export const getAllBusinessRegistrations = async () => {
  try {
    const response = await http.get(
      "/bussinessRegistration/getBusinessRegisteration/"
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSingleBusinessRegistrations = async (id) => {
  try {
    const response = await http.get(
      `/bussinessRegistration/getSingleBusinessRegisteration/${id}`
    );
    return response.data.data;
  } catch (error) {
    toast.error(error.data.message);
  }
};
