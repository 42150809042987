import { Route, Router, Routes } from "react-router";

import Layout from "./pages/layout/Layout";
import { allRoutes } from "./constants/routes";
import { RequireAuth } from "./components/requireAuth";
import { RequireBusinessAuth } from "./components/requireBusinessAuth";
import { Suspense, useEffect, useState } from "react";
import Loading from "./components/backdrop/Loading";
import { useSelector } from "react-redux";
import ServerErrorComponent from "./components/generic/ServerErrorComponent";
import { store } from "./redux/store";

function App() {
  // const [loading, setLoading] = useState(false);
  // const location = useLocation();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const token = localStorage.getItem("token");

  // const handleTokenExpiry = () => {
  //   navigate("/");
  // };

  // const deleteExpiredToken = () => {
  //   localStorage.removeItem("token");
  // };

  // useEffect(() => {
  //   if (token) {
  //     const decodedToken = jwtDecode(token);
  //     if (decodedToken.exp * 1000 < new Date().getTime()) {
  //       dispatch(logout());
  //       deleteExpiredToken();
  //     }
  //   }
  // }, [location]);

  // if (loading) {
  //   return <Loading />;
  // }
  const { isServerError } =  useSelector(state => state.system);

  return isServerError ? (
    <ServerErrorComponent />
  ) : (
    <div className="App bg-white">
      <Suspense fallback={<Loading />}>
        <Routes>
          {allRoutes.map((routeElement) =>
            !routeElement.isProtected ? (
              <Route
                exact={routeElement.exact}
                path={routeElement.path}
                element={
                  routeElement.isBusinessProtected ? (
                    <RequireBusinessAuth>
                      {routeElement.element}
                    </RequireBusinessAuth>
                  ) : (
                    routeElement.element
                  )
                }
                key={routeElement.id}
              />
            ) : (
              <Route
                exact={routeElement.exact}
                path={routeElement.path}
                element={
                  <Layout>
                    <RequireAuth>{routeElement.element}</RequireAuth>
                  </Layout>
                }
                key={routeElement.id}
              />
            )
          )}
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
