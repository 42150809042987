import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Save } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editPortalProfile } from "../../services/portalServices";
import { toast } from "react-toastify";
import http from "../../http-common";
import { logout } from "../../redux/Slices/Auth";
import PasswordStrengthBar from "react-password-strength-bar";
import { useState } from "react";
const ChangeUserPasswordForm = ({ setShowChangePasswordForm }) => {
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showNewConfirmPass, setShowNewConfirmPass] = useState(false);

  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      password: "",
      confirmPassword: "",
    },
    onSubmit: async (values, formikHelper) => {
      try {
        const response = await http.put("/client/updateClientPassword", values);
        if (response.data.success) {
          localStorage.clear();
          formikHelper.resetForm();
          toast.success("Password successfully changed");
          setShowChangePasswordForm(false);
          dispatch(logout());
        }
      } catch (error) {
        console.log(error.response.data);
        toast.error(error.response.data?.message || "Cannot change password");
      }
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, "Password should be at least 8 characters")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
          {
            message:
              "Password should include at least one special character, number, lowercase, and uppercase letter.",
          }
        )
        .required("Required"),
      // .min(
      //   8,
      //   "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
      // ),
      currentPassword: Yup.string()
        .required("Required")
        .min(
          8,
          "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
        ),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
  });
  return (
    <Fade cascade>
      {formik.isSubmitting ? (
        <div className="d-flex align-items-center justify-content-center py-72">
          <Spinner animation="grow" variant="success" />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className="row g-3 mt-5">
          <div className="col-12">
            <h4 className="h4">Change Password</h4>
          </div>
          <div className="col-12 relative">
            <label htmlFor="currentPassword" className="form-label">
              Current Password
              <sup className="text-danger">*</sup>
            </label>
            <input
              type={showPass ? "text" : "password"}
              id="currentPassword"
              value={formik.values.currentPassword}
              onChange={formik.handleChange}
              className="form-control w-100 relative"
              placeholder="Current Password"
            />
            {formik.errors?.currentPassword && (
              <p className="text-xs text-danger">
                {formik.errors?.currentPassword}
              </p>
            )}
            <span
              className="absolute top-9 right-5 pointer"
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </span>
          </div>
          <div className="col-12 relative">
            <label htmlFor="password" className="form-label">
              New Password
              <sup className="text-danger">*</sup>
            </label>
            <input
              type={showNewPass ? "text" : "password"}
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              className="form-control w-100 relative"
              placeholder="New Password"
            />
            {formik.errors?.password && (
              <p className="text-xs text-danger mt-1">
                {formik.errors?.password}
              </p>
            )}
            <span
              className="absolute top-9 right-5 pointer"
              onClick={() => setShowNewPass(!showNewPass)}
            >
              {showNewPass ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </span>
            <PasswordStrengthBar
              key={formik.touched.password + formik.errors.password}
              password={!formik.errors.password ? formik.values.password : ""}
              minimumScore={3}
              scoreGroups={{
                short: 0,
                bad: 1,
                weak: 2,
                good: 3,
                strong: 4,
                veryStrong: 5,
              }}
              className="h-4 mt-3"
            />
            <div
              className={`mt-1 text-red-500 ${
                formik.values.password ? "d-none" : "block"
              }`}
            >
              <small>
                Password should include at least one special character, number,
                lowercase, uppercase letter and should be at least 8 characters.
              </small>
            </div>
          </div>
          <div className="col-12 relative">
            <label
              htmlFor="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              className="form-label"
            >
              Confirm New Password
              <sup className="text-danger">*</sup>
            </label>
            <input
              type={showNewConfirmPass ? "text" : "password"}
              id="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              className="form-control w-100 relative"
              placeholder="Confirm New Password"
            />
            {formik.errors?.confirmPassword && (
              <p className="text-xs text-danger">
                {formik.errors?.confirmPassword}
              </p>
            )}
            <span
              className="absolute top-9 right-5 pointer"
              onClick={() => setShowNewConfirmPass(!showNewConfirmPass)}
            >
              {showNewConfirmPass ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>
              )}
            </span>
          </div>

          <div className="col-12">
            <button
              type="submit"
              className="btn btn-success d-flex align-items-center justify-content-center"
            >
              <Save sx={{ mr: 2 }} /> Update Password
            </button>
          </div>
        </form>
      )}
    </Fade>
  );
};

export default ChangeUserPasswordForm;
