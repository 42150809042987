import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./CreateFolderModal.scss";
import { toast } from "react-toastify";
import { addFolder } from "../../services/docService";
import { CloseIcon } from "../../assets/icons/icons";

export const CreateFolderModal = ({ show, close, updateDocuments }) => {
  const [folderName, setFolderName] = useState("");
  const [validationError, setValidationError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!folderName.trim()) {
        setValidationError("Please enter a folder name");
        return;
      }

      const data = {
        folderName: folderName,
      };

      await addFolder(data);
      toast.success("Folder created successfully");
      close();
      updateDocuments();
      setFolderName("");
      window.location.reload();
    } catch (error) {
      toast.error("Error creating folder");
      console.error("Error creating folder:", error);
    }
  };

  const handleInputChange = (e) => {
    setFolderName(e.target.value);
    setValidationError("");
  };

  return (
    <Modal
      className="fade modal-overlay"
      show={show}
      size="lg"
      onHide={() => close()}
    >
      <div className="folder-form">
        <Modal.Header>
          <Modal.Title>Create A New Folder</Modal.Title>
          <Button variant="" className="btn-close" onClick={() => close()}>
            <CloseIcon />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="folder-input">
            <div className="create-form-content">
              <label>Name</label>
              <input
                type="text"
                name="folderName"
                value={folderName}
                onChange={handleInputChange}
              />
            </div>
            {validationError && (
              <div className="error-message">{validationError}</div>
            )}
            <div onClick={handleSubmit} className="submit-btn">
              Submit
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};
