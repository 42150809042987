import http from "../http-common";

export const editPortalProfile = async (submitted) => {
  const data = await http
    .put(`/client/updateClient`, submitted)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

// Get business messages

export const getClientMessages = async (setLoading, setMessages) => {
  const data = await http
    .get("/clientMessage")
    .then((res) => {
      setLoading(false);
      setMessages(res.data.data);
    })
    .catch((e) => {
      setLoading(false);
      console.log(e);
    });

  return data;
};

// Get 3 Recent Messages
export const getRecentMessages = async (setMessages) => {
  const data = await http
    .get("/clientMessage")
    .then((res) => {
      setMessages(res.data.data);
    })
    .catch((e) => {
      console.log(e);
    });

  return data;
};

// Get business messages

export const getSingleClientMessages = async (id) => {
  try {
    const { data } = await http.get(`/clientMessage/${id}`);
    return {
      ...data,
      success: true,
      message: "Message fetched successfully",
    };
  } catch (err) {
    console.log(err.response);
    return {
      success: false,
      message: err.response?.data?.message || "Cannot find this message",
    };
  }
};

export const getProcurementMethod = async () => {
  const data = await http
    .get(`/procurementMethod/`)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.log(error.response.data.message);
      return error.response.data.message;
    });

  return data;
};
