import React, { useState, useEffect } from "react";
import "./viewDocumentsCopy.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router";
import { getFolders } from "../../services/docService";
import { FolderIcon } from "../../assets/icons/icons";
import { CreateFolderModal } from "../../components/createFolderModal/CreateFolderModal";

export const ViewDocumentsCopy = ({ user }) => {
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const fetchDocs = async () => {
    try {
      const response = await getFolders();
      setDocuments(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchDocs();
  }, []);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const updateDocuments = () => {
    fetchDocs();
  };

  return (
    <div className="file-manager">
      {/* <Navbar user={user} /> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="letterBackBtn" onClick={() => navigate(-1)}>
          <ArrowBackIcon />
          <span>Back</span>
        </div>
        <div className="create-btn" onClick={handleToggle}>
          create
        </div>
      </div>

      <div className="file-manager__content">
        <div className="file-manager__content--sub-header">
          <div className="content-title">documents</div>
        </div>

        <div className="file-manager__content--list">
          {documents.length < 1 ? (
            <div className="void-folder">no folders available.</div>
          ) : (
            documents.map((folder) => (
              <div
                onDoubleClick={() => navigate(`/view-docs-copy/${folder.id}`)}
                className="folder"
                key={folder?.folderId}
              >
                <div className="folder__icon">
                  <FolderIcon />
                </div>
                <div className="folder__name">{folder?.folderName}</div>
              </div>
            ))
          )}
        </div>
      </div>
      <CreateFolderModal
        show={toggle}
        close={handleToggle}
        updateDocuments={updateDocuments}
      />
    </div>
  );
};
