import "./navbar.scss";
import logo from "../../assets/icons/logo.jpeg";
import profile from "../../assets/icons/avatar.png";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailIcon from "@mui/icons-material/Mail";
import FolderIcon from "@mui/icons-material/Folder";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Logout from "../../pages/logout/Logout";
import { verifyUser } from "../../services/authService";
import Loading from "../backdrop/Loading";

const Navbar = ({ user }) => {
  const [logout, setLogout] = useState(false);
  const displayLogout = () => {
    setLogout(!logout);
  };

  const [loading, setLoading] = useState(false);
  // const [user, setUser] = useState({})
  // const fetchData = async () => {
  //     setLoading(true);
  //     const response = await verifyUser();
  //     setLoading(false);
  //     console.log("Resss", response)
  //     setUser(response.data.clientData);
  // };

  // useEffect(() => {
  //     fetchData()
  // }, [])

  const firstLetter = user?.firstName?.charAt(0).toUpperCase();
  const lastLetter = user?.lastName?.charAt(0).toUpperCase();

  let logoutRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!logoutRef.current.contains(e.target)) {
        setLogout(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="navbar">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <p className="heading">Client Portal</p>
      <div className="profile1" ref={logoutRef}>
        <Link to="/view-docs-copy">
          <FolderIcon className="icon" />
        </Link>
        <Link to="/client-message">
          <MailIcon className="icon" />
        </Link>
        {/* <NotificationsNoneIcon className="icon" /> */}
        {/* <img onClick={displayLogout} src={profile} alt="" className="person" /> */}

        {firstLetter && lastLetter ? (
          <p
            style={{
              backgroundColor: "darkblue",
              color: "white",
              padding: ".4rem",
              borderRadius: "50%",
            }}
            onClick={displayLogout}
            className="profilee"
          >
            {`${firstLetter}${lastLetter}`}
          </p>
        ) : null}

        {logout && <Logout user={user} />}
      </div>
    </div>
  );
};

export default Navbar;
