import React from "react";
import "../backButton/BackBtn.scss";
import { useNavigate } from "react-router";
import { FaChevronLeft } from "react-icons/fa6";

const BackBtn = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div className="mb-3">
      <button
        className="h-full flex gap-2 btn-sm bg-green-600 hover:bg-green-700 p-1 px-3 rounded-xl text-white items-center justify-center  border-none outline-none  text-sm text-nowrap"
        onClick={handleClick}
      >
        <FaChevronLeft />
        <span>Back</span>
      </button>
    </div>
  );
};

export default BackBtn;
