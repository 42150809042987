// import { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
// import { toast } from "react-toastify";
// import Loading from "../../components/backdrop/Loading";
// import { logout } from "../../redux/Slices/Auth";
// import { verifyUser } from "../../services/authService";
// import "./logout.scss";
// import LogoutIcon from "@mui/icons-material/Logout";
// import profile from "../../assets/icons/avatar.png";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import axios from "axios";
// import { useNavigate } from "react-router";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import Person2Icon from "@mui/icons-material/Person2";
// import { Link } from "react-router-dom";

// const Logout = ({ user }) => {
//   const [loading, setLoading] = useState(false);

//   const navigate = useNavigate();

//   const dispatch = useDispatch();
//   const logOut = () => {
//     dispatch(logout());
//   };

//   const [currentPassword, setCurrentPassword] = useState("");
//   const [password, setPassword] = useState("");
//   const [confirmPassword, setConfirmPassword] = useState("");

//   const token = localStorage.getItem("token");

//   const changePassword = async (e) => {
//     e.preventDefault();
//     const submitted = {
//       currentPassword,
//       password,
//       confirmPassword,
//     };
//     try {
//       setLoading(true);
//       const response = await http.put(
//         "/client/updateClientPassword",
//         submitted,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setLoading(false);
//       if (response.data.success) {
//         localStorage.clear();
//         navigate("/login");
//         toast.success("Password successfully changed");
//       }
//     } catch (error) {
//       setLoading(false);
//       console.log(error.response.data);
//       toast.error(error.response.data.message);
//     }
//   };

//   const [changePass, setChangePass] = useState(false);
//   const openChangePass = () => {
//     setChangePass(!changePass);
//   };

//   //eye icon
//   const [eyeIcon1, setEyeIcon1] = useState(false);
//   const handleEyeIcon1 = () => {
//     setEyeIcon1((prevState) => !prevState);
//   };
//   const [eyeIcon2, setEyeIcon2] = useState(false);
//   const handleEyeIcon2 = () => {
//     setEyeIcon2((prevState) => !prevState);
//   };
//   const [eyeIcon3, setEyeIcon3] = useState(false);
//   const handleEyeIcon3 = () => {
//     setEyeIcon3((prevState) => !prevState);
//   };
//   console.log(user);

//   const firstLetter = user?.firstName.charAt(0).toUpperCase();
//   const lastLetter = user?.lastName.charAt(0).toUpperCase();

//   if (loading) {
//     return <Loading />;
//   }
//   return (
//     <div className="logout">
//       <div className="person-container">
//         {/* <img src={profile} alt="" className="person1" /> */}
//         {firstLetter && lastLetter ? (
//           <p
//             style={{
//               backgroundColor: "darkblue",
//               color: "white",
//               fontSize: "3rem",
//               padding: "1rem",
//               width: "65px",
//               height: "60px",
//               borderRadius: "50%",
//             }}
//           // className="profilee"
//           >
//             {`${firstLetter}${lastLetter}`}
//           </p>
//         ) : (
//           <Loading />
//         )}
//       </div>
//       <div className="user">
//         <p
//           style={{
//             fontSize: "large",
//           }}
//         >
//           {user?.firstName}
//         </p>
//         <p
//           style={{
//             cursor: "pointer",
//             paddingTop: "3px",
//             letterSpacing: "1px",
//           }}
//         >
//           {user?.email}
//         </p>
//       </div>
//       {/* <p className="open-pass" onClick={openChangePass}>
//         <span>Change Password</span>
//         <ExpandMoreIcon className="icon" />
//       </p> */}
//       <div className={changePass ? "change-pass1" : "change-pass-close"}>
//         <form action="" onSubmit={changePassword}>
//           {/* <p>Change Your Password</p> */}
//           <div className="input-icons">
//             {/* <input
//               type={eyeIcon1 ? "text" : "password"}
//               placeholder="Enter old password"
//               value={currentPassword}
//               onChange={(e) => {
//                 setCurrentPassword(e.target.value);
//               }}
//             /> */}
//             {/* <div onClick={handleEyeIcon1} className="visibility">
//               {eyeIcon1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
//             </div> */}
//             {/* <div className="user">
//               <p style={{
//                 fontSize: "large"
//               }}>{user.firstName}</p>
//               <p style={{
//                 cursor: "pointer",
//                 paddingTop: "3px",
//                 letterSpacing: "1px"
//               }}>{user.email}</p>
//               <div className="insidee">
//                 <p style={{
//                   fontSize: "large"
//                 }}>{user.institution}</p>
//                 <p style={{
//                   cursor: "pointer",
//                   paddingTop: "3px",
//                   letterSpacing: "1px"
//                 }}>{user.phone}</p>
//               </div>
//             </div> */}
//             <p className="open-pass" onClick={openChangePass}>
//               <span>Change Password</span>
//               <ExpandMoreIcon className="icon" />
//             </p>
//             <div className={changePass ? "change-pass" : "change-pass-close"}>
//               <form action="" onSubmit={changePassword}>
//                 {/* <p>Change Your Password</p> */}
//                 <div className="input-icon">
//                   <input
//                     type={eyeIcon1 ? "text" : "password"}
//                     placeholder="Enter old password"
//                     value={currentPassword}
//                     onChange={e => {
//                       setCurrentPassword(e.target.value)
//                     }}
//                   />
//                   <div onClick={handleEyeIcon1} className="visibility">
//                     {eyeIcon1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
//                   </div>
//                 </div>

//                 <div className="input-icon">
//                   <input
//                     type={eyeIcon2 ? "text" : "password"}
//                     placeholder="Enter new password"
//                     value={password}
//                     onChange={(e) => {
//                       setPassword(e.target.value);
//                     }}
//                   />
//                   <div onClick={handleEyeIcon2} className="visibility">
//                     {eyeIcon2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
//                   </div>
//                 </div>

//                 <div className="input-icon">
//                   <input
//                     type={eyeIcon3 ? "text" : "password"}
//                     placeholder="Confirm new password"
//                     value={confirmPassword}
//                     onChange={(e) => {
//                       setConfirmPassword(e.target.value);
//                     }}
//                   />
//                   <div onClick={handleEyeIcon3} className="visibility">
//                     {eyeIcon3 ? <VisibilityOffIcon /> : <VisibilityIcon />}
//                   </div>
//                 </div>
//                 <div className="change">
//                   <button type="submit">Change Password</button>
//                 </div>
//               </form>
//             </div>
//             <Link
//               to="/client-profile"
//               className={changePass ? "btn-close" : "btn"}
//               style={{
//                 textDecoration: "none",
//               }}
//             >
//               <Person2Icon className="icon" />
//               <p>View Profile</p>
//             </Link>
//             <div onClick={logOut} className={changePass ? "btn-close" : "btn"}>
//               <LogoutIcon className="icon" />
//               <p className="log">Logout</p>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default Logout;


import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../../components/backdrop/Loading";
import { logout } from "../../redux/Slices/Auth";
import { verifyUser } from "../../services/authService";
import "./logout.scss";
import LogoutIcon from '@mui/icons-material/Logout';
import profile from '../../assets/icons/avatar.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { useNavigate } from "react-router";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Person2Icon from '@mui/icons-material/Person2';
import { Link } from "react-router-dom";
import http from "../../http-common";

const Logout = ({ user }) => {

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout())
  }

  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('')

  const token = localStorage.getItem("token")

  const changePassword = async (e) => {
    e.preventDefault();
    const submitted = {
      currentPassword,
      password,
      confirmPassword
    }
    try {
      setLoading(true)
      const response = await http.put(
        '/client/updateClientPassword',
        submitted,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false)
      if (response.data.success) {
        localStorage.clear();
        navigate("/login")
        toast.success("Password successfully changed")
      }
    } catch (error) {
      setLoading(false)
      console.log(error.response.data);
      toast.error(error.response.data.message)
    }
  }

  const [changePass, setChangePass] = useState(false);
  const openChangePass = () => {
    setChangePass(!changePass)
  }

  //eye icon
  const [eyeIcon1, setEyeIcon1] = useState(false);
  const handleEyeIcon1 = () => {
    setEyeIcon1(prevState => !prevState)
  }
  const [eyeIcon2, setEyeIcon2] = useState(false);
  const handleEyeIcon2 = () => {
    setEyeIcon2(prevState => !prevState)
  }
  const [eyeIcon3, setEyeIcon3] = useState(false);
  const handleEyeIcon3 = () => {
    setEyeIcon3(prevState => !prevState)
  }

  const firstLetter = user?.firstName.charAt(0).toUpperCase();
  const lastLetter = user?.lastName.charAt(0).toUpperCase();

  if (loading) {
    return (
      <Loading />
    )
  }
  return (
    <div className="logout">
      <div className="person-container">
        {/* <img src={profile} alt="" className="person1" /> */}
        {firstLetter && lastLetter ? (
          <p
            style={{
              backgroundColor: "darkblue",
              color: "white",
              fontSize: "3rem",
              padding: "1rem",
              width: "65px",
              height: "60px",
              borderRadius: "50%",
            }}
          // className="profilee"
          >
            {`${firstLetter}${lastLetter}`}
          </p>
        ) : (
          <Loading />
        )}
      </div>
      <div className="user">
        <p style={{
          fontSize: "large"
        }}>{user.firstName}</p>
        <p style={{
          cursor: "pointer",
          paddingTop: "3px",
          letterSpacing: "1px"
        }}>{user.email}</p>
        <div className="insidee">
          <p style={{
            fontSize: "large"
          }}>{user.institution}</p>
          <p style={{
            cursor: "pointer",
            paddingTop: "3px",
            letterSpacing: "1px"
          }}>{user.phone}</p>
        </div>
      </div>
      <p className="open-pass" onClick={openChangePass}>
        <span>Change Password</span>
        <ExpandMoreIcon className="icon" />
      </p>
      <div className={changePass ? "change-pass" : "change-pass-close"}>
        <form action="" onSubmit={changePassword}>
          {/* <p>Change Your Password</p> */}
          <div className="input-icon">
            <input
              type={eyeIcon1 ? "text" : "password"}
              placeholder="Enter old password"
              value={currentPassword}
              onChange={e => {
                setCurrentPassword(e.target.value)
              }}
            />
            <div onClick={handleEyeIcon1} className="visibility">
              {eyeIcon1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </div>
          </div>

          <div className="input-icon">
            <input
              type={eyeIcon2 ? "text" : "password"}
              placeholder="Enter new password"
              value={password}
              onChange={e => {
                setPassword(e.target.value)
              }}
            />
            <div onClick={handleEyeIcon2} className="visibility">
              {eyeIcon2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </div>
          </div>

          <div className="input-icon">
            <input
              type={eyeIcon3 ? "text" : "password"}
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={e => {
                setConfirmPassword(e.target.value)
              }}
            />
            <div onClick={handleEyeIcon3} className="visibility">
              {eyeIcon3 ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </div>
          </div>
          <div className="change">
            <button type="submit">Change Password</button>
          </div>
        </form>
      </div>
      <Link to="/client-profile" className={changePass ? "btn-close" : "btn"} style={{
        textDecoration: "none"
      }}>
        <Person2Icon className="icon" />
        <p>View Profile</p>
      </Link>
      <div onClick={logOut} className={changePass ? "btn-close" : "btn"}>
        <LogoutIcon className="icon" />
        <p className="log">Logout</p>
      </div>
    </div>
  );
}

export default Logout;
