export const FolderIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 32" fill="#9acd32">
      <path d="M8.431 9.155h20.958c2.158 0 2.158-2.238.084-2.238H14.987c-.83 0-1.244-1.244-1.244-1.244S13.162 3.848 12 3.848H1.211C-.365 3.848.049 5.673.049 5.673s2.407 20.47 2.573 21.715S4.075 29 4.075 29l2.821-18.103c.208-1.327 1.12-1.7 1.535-1.742zm25.227.787H9.095c-.733 0-1.328.594-1.328 1.327l-2.572 16.4c0 .734.595 1.328 1.328 1.328h24.563c.732 0 1.328-.594 1.328-1.328l2.572-16.4c0-.733-.593-1.327-1.328-1.327z"></path>
    </svg>
  );
};

export const FileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M26.313 18.421c-.427-.42-1.372-.643-2.812-.662-.974-.011-2.147.075-3.38.248-.552-.319-1.122-.665-1.568-1.083-1.202-1.122-2.205-2.68-2.831-4.394.041-.16.075-.301.108-.444 0 0 .677-3.846.498-5.146a1.526 1.526 0 00-.088-.369l-.059-.151c-.184-.425-.545-.875-1.111-.85l-.341-.011c-.631 0-1.146.323-1.281.805-.411 1.514.013 3.778.781 6.711l-.197.478c-.55 1.34-1.238 2.689-1.846 3.88l-.079.155a65.82 65.82 0 01-1.745 3.213l-.543.287c-.04.021-.97.513-1.188.645-1.852 1.106-3.079 2.361-3.282 3.357-.065.318-.017.725.313.913l.525.264c.228.114.468.172.714.172 1.319 0 2.85-1.643 4.959-5.324a49.318 49.318 0 017.638-1.815c1.852 1.043 4.129 1.767 5.567 1.767.255 0 .475-.024.654-.072a1.11 1.11 0 00.65-.444c.279-.42.335-.998.26-1.59-.023-.176-.163-.393-.315-.541zM6.614 25.439c.241-.658 1.192-1.958 2.6-3.111.088-.072.306-.276.506-.466-1.472 2.348-2.458 3.283-3.106 3.577zM14.951 6.24c.424 0 .665 1.069.685 2.07s-.214 1.705-.505 2.225c-.241-.77-.357-1.984-.357-2.778 0 0-.018-1.517.177-1.517zm-2.487 13.682c.295-.529.603-1.086.917-1.677a31.984 31.984 0 001.609-3.511c.716 1.303 1.608 2.41 2.656 3.297.131.111.269.222.415.333-2.132.422-3.974.935-5.596 1.558zm13.439-.12c-.13.081-.502.128-.741.128-.772 0-1.727-.353-3.066-.927.515-.038.986-.057 1.409-.057.774 0 1.004-.003 1.761.19s.767.585.637.667z"></path>
      <path d="M28.681 7.159c-.694-.947-1.662-2.053-2.724-3.116s-2.169-2.03-3.116-2.724C21.229.137 20.448 0 20 0H4.5A2.503 2.503 0 002 2.5v27C2 30.878 3.121 32 4.5 32h23c1.378 0 2.5-1.122 2.5-2.5V10c0-.448-.137-1.23-1.319-2.841zm-4.138-1.702A27.334 27.334 0 0126.811 8H22V3.189a27.334 27.334 0 012.543 2.268zM28 29.5c0 .271-.229.5-.5.5h-23a.507.507 0 01-.5-.5v-27c0-.271.229-.5.5-.5H20v7a1 1 0 001 1h7v19.5z"></path>
    </svg>
  );
};

export const BackIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M12.586 27.414l-10-10a2 2 0 010-2.828l10-10a2 2 0 112.828 2.828L8.828 14H28a2 2 0 110 4H8.828l6.586 6.586c.39.39.586.902.586 1.414s-.195 1.024-.586 1.414a2 2 0 01-2.828 0z"></path>
    </svg>
  );
};

export const UserIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 28">
      <path d="M20 21.859C20 24.14 18.5 26 16.672 26H3.328C1.5 26 0 24.141 0 21.859 0 17.75 1.016 13 5.109 13a6.979 6.979 0 009.782 0C18.985 13 20 17.75 20 21.859zM16 8c0 3.313-2.688 6-6 6s-6-2.688-6-6 2.688-6 6-6 6 2.688 6 6z"></path>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M31.708 25.708L22 16l9.708-9.708a1 1 0 000-1.414L27.122.292a1 1 0 00-1.414-.001L16 9.999 6.292.291a.998.998 0 00-1.414.001L.292 4.878a1 1 0 000 1.414L10 16 .292 25.708a.999.999 0 000 1.414l4.586 4.586a1 1 0 001.414 0L16 22l9.708 9.708a1 1 0 001.414 0l4.586-4.586a.999.999 0 000-1.414z"></path>
    </svg>
  );
};
