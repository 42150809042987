import { Navigate } from "react-router";
import { verifyUser } from "../services/authService";
import React, { useEffect, useState } from "react";
import { logout, setClienData } from "../redux/Slices/Auth";
import { useDispatch } from "react-redux";
import Loading from "./backdrop/Loading";
import { toast } from "react-toastify";

export const RequireAuth = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [user, setUser] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await verifyUser(dispatch);
        setLoading(false);

        console.log("response", response);

        if (!response.data.success) {
          toast.error("Invalid token");
          dispatch(logout());
          return;
        }

        if (response.tokenExpired) {
          toast.error("Token has expired");
          dispatch(logout());
          return;
        }
        dispatch(setClienData(response.data.clientData))
        setUser(response.data.clientData);
      } catch (error) {
        setLoading(false);
        toast.error(error.message);
        dispatch(logout());
        return <Navigate to="/" />;
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }

  // return children
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { user });
    }
    return child;
  });

  return childrenWithProps;
};
