import { lazy } from "react";
import { FaClockRotateLeft, FaFolder, FaTrash } from "react-icons/fa6";
import { ViewDocumentsCopy } from "../pages/viewDocumentsCopy/ViewDocumentsCopy";
import { ViewDocumentCopyList } from "../pages/ViewDocumentsCopyList/ViewDocumentCopyList";
import ResendEmail from "../pages/forgotPassword/BusinessClient/ResendEmail";
import EmailVerificationSuccess from "../pages/forgotPassword/BusinessClient/EmailVerificationSuccess";
import EmailVerificationError from "../pages/forgotPassword/BusinessClient/EmailVerificationError";
import { ViewCertificate } from "../components/view-certificate/ViewCertificate";
import { BusinessRegisteration } from "../pages/searchBusiness/SearchBusiness";
const Home = lazy(() => import("../pages/home/Home"));
const Login = lazy(() => import("../pages/login/Login"));
const LoginBussiness = lazy(() =>
  import("../pages/bussiness/login/LoginBussiness")
);
const Register = lazy(() => import("../pages/register/Register"));
const RegisterBusiness = lazy(() => import("../pages/bussiness/signup/Signup"));
const EnterEmail = lazy(() =>
  import("../pages/forgotPassword/BusinessClient/EnterEmail")
);
const ConfirmCode = lazy(() =>
  import("../pages/forgotPassword/BusinessClient/ConfirmCode")
);
const ChangePassword = lazy(() =>
  import("../pages/forgotPassword/BusinessClient/ChangePassword")
);
const ClientEmail = lazy(() =>
  import("../pages/forgotPassword/client/ClientEmail")
);
const GetClientCode = lazy(() =>
  import("../pages/forgotPassword/client/GetClientCode")
);
const ChangeClientPassword = lazy(() =>
  import("../pages/forgotPassword/client/ChangeClientPassword")
);
const ViewProfile = lazy(() => import("../pages/viewProfile/ViewProfile"));
const ClientProfile = lazy(() => import("../pages/logout/ClientProfile"));
const RegisterBussiness = lazy(() =>
  import("../pages/bussiness/register/RegisterBussiness")
);
const ViewBussiness = lazy(() =>
  import("../pages/bussiness/viewBussiness/ViewBussiness")
);
const BusinessDetail = lazy(() =>
  import("../pages/bussiness/bussinessDetail/BusinessDetail")
);
const Certificate = lazy(() =>
  import("../components/VIewCertificate/Certificate")
);
const ClientMessage = lazy(() => import("../pages/clientMessages/Messages"));
const SingleClientMessage = lazy(() =>
  import("../pages/clientMessages/SingleClientMessage")
);
const Dashboard = lazy(() => import("../components/main/Main"));
const DashboardBussiness = lazy(() =>
  import("../pages/bussiness/dashboard/DashboardBussiness")
);
const UploadDocument = lazy(() =>
  import("../pages/uploadDocument/UploadDocument")
);
const ViewDocument = lazy(() => import("../pages/viewDocument/ViewDocument"));
const ViewLetter = lazy(() => import("../pages/viewLetter/ViewLetter"));
const UploadAnnual = lazy(() =>
  import("../pages/uploadAnualPlan/UploadAnnual")
);
const UploadMonthly = lazy(() =>
  import("../pages/uploadMonthlyPlan/UploadMonthly")
);
const SingleDoc = lazy(() => import("../pages/singleDocument/SingleDoc"));
const EditDocument = lazy(() => import("../pages/editDocument/EditDocument"));
const EditBusiness = lazy(() =>
  import("../pages/bussiness/editBusiness/EditBusiness")
);
const PaymentPage = lazy(() =>
  import("../pages/bussiness/payment/PaymentPage")
);
const TariffPage = lazy(() => import("../pages/tariffPage/TariffPage"));
const NotFound = lazy(() => import("../pages/layout/NotFound"));
const BussinessMessage = lazy(() =>
  import("../pages/bussiness/bussinessMessages/Messages")
);
const SingleMessage = lazy(() =>
  import("../pages/bussiness/bussinessMessages/SingleMessage")
);
const RecentUploads = lazy(() =>
  import("../pages/recent-uploads/RecentUploads")
);
const YearCategories = lazy(() =>
  import("../pages/folderProjects/YearCategories")
);
const CategoryDocumentsAndFolders = lazy(() =>
  import("../pages/folderProjects/CategoryDocumentsAndFolders")
);
const ProjectDocumentsAndFolders = lazy(() =>
  import("../pages/folderProjects/ProjectDocumentsAndFolders")
);
const ViewDifferentDocuments = lazy(() =>
  import("../pages/folderProjects/ViewDifferentDocuments")
);
const SupplierFolder = lazy(() =>
  import("../pages/folderProjects/SupplierFolder")
);

// const ViewCertificate = lazy(() =>
//   import("../pages/bussiness/businessCertificate/ViewCertificates")
// );


export const topSideMenuLinks = [
  {
    id: "top-link-1",
    path: "/dashboard",
    text: "All Folders",
    icon: <FaFolder />,
  },
  {
    id: "top-link-2",

    path: "/recent-uploads",
    text: "Recent uploads",
    icon: <FaClockRotateLeft />,
  },
];

// all routes

export const allRoutes = [
  {
    id: "all-pages-home",
    path: "/",
    exact: true,
    element: <Home />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "businessRegistrations",
    path: "/business-registrations",
    exact: true,
    element: <BusinessRegisteration/>,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-login",
    path: "/login",
    exact: true,
    element: <Login />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "view-certificat",
    path: "/view-certificate/:id",
    exact: true,
    element: <ViewCertificate/>,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-login-business",
    path: "/login-bussiness",
    exact: true,
    element: <LoginBussiness />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-register",
    path: "/register",
    exact: true,
    element: <Register />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "business-register",
    path: "/business-register",
    exact: true,
    element: <RegisterBusiness />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-forgot-business-client-password",
    path: "/forgot-business-client-password",
    exact: true,
    element: <EnterEmail />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-resend-forgot-business-client-password",
    path: "/resend-email-verification-token",
    exact: true,
    element: <ResendEmail />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-email-verify-success",
    path: "/auth/verified/success",
    exact: true,
    element: <EmailVerificationSuccess />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-email-verify-failed",
    path: "auth/verified/error",
    exact: true,
    element: <EmailVerificationError />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-business-client-confirmation-code",
    path: "/business-client-confirmation-code",
    exact: true,
    element: <ConfirmCode />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-change-business-client-password",
    path: "/change-business-client-password",
    exact: true,
    element: <ChangePassword />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-forgot-client-password",
    path: "/forgot-client-password",
    exact: true,
    element: <ClientEmail />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-client-confirmation-code",
    path: "/client-confirmation-code",
    exact: true,
    element: <GetClientCode />,
    isBusinessProtected: false,
    isProtected: false,
  },

  {
    id: "all-pages-change-client-password",
    path: "/change-client-password",
    exact: true,
    element: <ChangeClientPassword />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-view-profile",
    path: "/view-profile",
    exact: true,
    element: <ViewProfile />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-client-profile",
    path: "/client-profile",
    exact: true,
    element: <ClientProfile />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-register-bussiness",
    path: "/register-bussiness",
    exact: true,
    element: <RegisterBussiness />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-view-bussiness",
    path: "/view-bussiness",
    exact: true,
    element: <ViewBussiness />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-bussiness-detail/:id",
    path: "/bussiness-detail/:id",
    exact: true,
    element: <BusinessDetail />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-certificate/:id",
    path: "/certificate/:id",
    exact: true,
    element: <Certificate />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-business-message",
    path: "/business-message",
    exact: true,
    element: <BussinessMessage />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-business-message/:id",
    path: "/business-message/:id",
    exact: true,
    element: <SingleMessage />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-dashboard",
    path: "/dashboard",
    exact: true,
    element: <Dashboard />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-year-categories",
    path: "/:year/categories",
    exact: true,
    element: <YearCategories />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-category-documents-and-folders",
    path: "/:year/:category",
    exact: true,
    element: <CategoryDocumentsAndFolders />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-project-documents-and-folders",
    path: "/:year/:category/:project/:projectId",
    exact: true,
    element: <ProjectDocumentsAndFolders />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-project-documents-and-folders",
    path: "/:year/:category/:project/:projectId/:supplier/:supplierId",
    exact: true,
    element: <SupplierFolder />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-view-document",
    path: "/:year/:category/:project/:projectId/:fileName",

    exact: true,
    element: <ViewDifferentDocuments />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-recent-uploads",
    path: "/recent-uploads",
    exact: true,
    element: <RecentUploads />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-dashboard-bussiness",
    path: "/dashboard-bussiness",
    exact: true,
    element: <DashboardBussiness />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-client-message",
    path: "/client-message",
    exact: true,
    element: <ClientMessage />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-client-message/:id",
    path: "/client-message/:id",
    exact: true,
    element: <SingleClientMessage />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-upload-doc",
    path: "/upload-doc",
    exact: true,
    element: <UploadDocument />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-recent-uploads",
    path: "/recent-files",
    exact: true,
    element: <ViewDocument />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-view-doc",
    path: "/view-doc",
    exact: true,
    element: <ViewDocument />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-view-docs-copy",
    path: "/view-docs-copy",
    exact: true,
    element: <ViewDocumentsCopy />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-view-docs-copy/:id",
    path: "/view-docs-copy/:id",
    exact: true,
    element: <ViewDocumentCopyList />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-view-letter/:id",
    path: "/view-letter/:id",
    exact: true,
    element: <ViewLetter />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-upload-annual",
    path: "/upload-annual",
    exact: true,
    element: <UploadAnnual />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-upload-monthly",
    path: "/upload-monthly",
    exact: true,
    element: <UploadMonthly />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-doc-details/:id",
    path: "/doc-details/:id",
    exact: true,
    element: <SingleDoc />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-edit-doc/:id",
    path: "/edit-doc/:id",
    exact: true,
    element: <EditDocument />,
    isBusinessProtected: false,
    isProtected: true,
  },
  {
    id: "all-pages-edit-business/:id",
    path: "/edit-business/:id",
    exact: true,
    element: <EditBusiness />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-payment",
    path: "/payment",
    exact: true,
    element: <PaymentPage />,
    isBusinessProtected: true,
    isProtected: false,
  },
  {
    id: "all-pages-tariff",
    path: "/tariff",
    exact: true,
    element: <TariffPage />,
    isBusinessProtected: false,
    isProtected: false,
  },
  {
    id: "all-pages-not-found",
    path: "*",
    exact: false,
    element: <NotFound />,
    isBusinessProtected: false,
    isProtected: false,
  },
];
