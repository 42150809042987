import { Modal, Button } from "react-bootstrap";
import "./uploadFieldModal.scss";
import { CloseIcon } from "../../assets/icons/icons";
import Select from "react-select";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useState, useEffect, useRef } from "react";
import io from "socket.io-client";
import Loading from "../backdrop/Loading";
import { toast } from "react-toastify";
import { uploadFile } from "../../services/docService";

export const UploadFileModal = ({ show, close, user, folderId, setFiles }) => {
  const [file, setFile] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [title, setTitle] = useState("");
  const [institution, setInstitution] = useState("");
  const socketRef = useRef();

  let token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    socketRef.current = io("http://localhost:5000");

    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  console.log(user);

  useEffect(() => {
    if (user && user.institution) {
      console.log(user);
    }
  }, [user]);

  const handleUpload = (e) => {
    e.preventDefault();
    if (title.length === 0) {
      toast.error("Title cannot be empty");
    } else if (!documentType) {
      toast.error("Please select a document type");
    } else if (!file) {
      toast.error("Please select a file");
    } else if (!file.name.endsWith(".pdf")) {
      toast.error("Please select a PDF file");
    } else {
      setLoading(true);

      let formData = new FormData();
      formData.append("document", file);
      formData.append("documentType", documentType.value);
      formData.append("documentTitle", title);
      formData.append("folderId", folderId);
      formData.append("institution", institution);

      uploadFile(formData, token)
        .then((res) => {
          setLoading(false);
          toast.success("Successfully uploaded");
          close();
          console.log("resss");
          console.log(res);

          setFiles((prevFiles) => [
            ...prevFiles,
            { ...res.data, filename: file.name },
          ]);
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Something went wrong while uploading");
          console.log(err.messages);
        });
    }
  };

  const options = [
    { value: "bidding document", label: "Bidding document" },
    { value: "monthly report", label: "Monthly report" },
    { value: "annual report", label: "Annual report" },
    { value: "bid Submission", label: "Bid Submission" },
    { value: "bid evaluation", label: "Bid Evaluation" },
    { value: "others", label: "Others" },
  ];

  useEffect(() => {
    setInstitution(user?.institution || "");
  }, [user]);

  return (
    <Modal
      className="fade modal-overlay"
      show={show}
      size="lg"
      onHide={() => close()}
    >
      <div className="folder-form">
        <Modal.Header>
          <Modal.Title className="title">Upload Folder</Modal.Title>
          <Button variant="" className="btn-close" onClick={() => close()}>
            <CloseIcon />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpload}>
            <div className="folder-input">
              <div className="form-input">
                <input
                  type="text"
                  placeholder="Document Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="select-input">
                <Select
                  options={options}
                  placeholder="Document Category"
                  onChange={setDocumentType}
                />
              </div>
              <div className="form-input">
                <input
                  type="text"
                  placeholder="Institution"
                  value={institution}
                  onChange={(e) => setInstitution(e.target.value)}
                />
              </div>
              <div className="form-input">
                <div className="file">
                  <label htmlFor="input-file">
                    <CloudUploadIcon />
                    <div className="file-input-label">
                      {file ? file?.name : "Select a file"}
                    </div>
                  </label>
                  <input
                    id="input-file"
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="file-upload-btn" onClick={handleUpload}>
                <span>Upload</span>
                <div className="upload-icon">
                  <CloudUploadIcon />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </div>
    </Modal>
  );
};
