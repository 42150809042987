import React, { Fragment, useState, useEffect } from "react";
import { Modal, Button, Card } from "react-bootstrap";
import { getSingleBusinessRegistrations } from "../../services/bussinessServices";
import Loading from "../../components/backdrop/Loading";

const ViewBusinessModal = ({ show, close, id }) => {
  const [loading, setLoading] = useState(false);
  const [BusinessData, setBusinessData] = useState();

  useEffect(() => {
    const fetechBusness = async () => {
      try {
        setLoading(true);
        const response = await getSingleBusinessRegistrations(id);
        setBusinessData(response);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    if (show) {
      fetechBusness();
    }
  }, [id, show]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <Modal className="fade" size="xl" show={show} onHide={() => close()}>
        <Modal.Header>
          <Modal.Title>Business Registration</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => close()}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div
              style={{
                display: " flex",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Name:</strong>
                  <div>{BusinessData?.nameOfBusiness || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Email:</strong>
                  <div>{BusinessData?.email || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Phone Number:</strong>
                  <div>{BusinessData?.phoneNumber || "N/a"}</div>
                </div>
                <div>
                  <strong>Type Of Business:</strong>
                  <div>{BusinessData?.typeOfBusiness || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Business Status:</strong>
                  <div>
                    {BusinessData?.businessStatus === "pending" ? (
                      <span className="badge bg-danger text-white">
                        Pending
                      </span>
                    ) : (
                      <span className="badge bg-secondary text-white">
                        Registered
                      </span>
                    )}
                  </div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Nature Of Business:</strong>
                  <div>
                    {BusinessData?.natureOfBusiness.slice(1, -1) || "N/a"}
                  </div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Email:</strong>
                  <div>{BusinessData?.email || "N/a"}</div>
                </div>
              </div>

              <div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Name:</strong>
                  <div>{BusinessData?.address1 || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Country:</strong>
                  <div>{BusinessData?.country || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Phone city:</strong>
                  <div>{BusinessData?.city || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Fully Owned:</strong>
                  <div>{BusinessData?.email || "N/a"}</div>
                </div>

                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Website:</strong>
                  <div>{BusinessData?.website || "N/a"}</div>
                </div>
                <div
                  style={{ display: " flex", gap: "3px", marginBottom: "4px" }}
                >
                  <strong>Total Amount:</strong>
                  <div>{BusinessData?.totalAmount || "N/a"}</div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};

export default ViewBusinessModal;
