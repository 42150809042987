import { createSlice } from "@reduxjs/toolkit";
import { verifyUser } from "../../services/authService";
import { useDispatch } from "react-redux";

export const clientSlice = createSlice({
    name: "client",
    initialState: {
        authClient: null,
        token: localStorage.getItem("token"),
        pending: false,
        error: false,
        errorMsg: "",
    },

    reducers: {
        clientLoginSuccess: (state, action) => {
            state.pending = false;
            state.authClient = action.payload;
            state.error = false;
            state.errorMsg = "";
        },
        testVerify: (state) => {
            console.log("Test verifieeedd")
        },
        userDetails: async (state) => {
            const res = await verifyUser()
            state.authClient = res.data.clientData
        }
    },
});

export const {
    clientLoginSuccess,
    testVerify,
    userDetails
} = clientSlice.actions;
export default clientSlice.reducer;
