import { createSlice } from "@reduxjs/toolkit";

const systemSlice = createSlice({
  name: "systemSlice",
  initialState: {
    isServerError: false,
  },
  reducers: {
    setIsServerError: (state) => {
      state.isServerError = true;
    },
    resetIsServerError: (state) => {
      state.isServerError = false;
    },
  },
});

export const { setIsServerError, resetIsServerError } = systemSlice.actions;

export default systemSlice.reducer;
