import React from "react";
import { Link } from "react-router-dom";

import UnauthenticateNavbar from "../../../components/unauthenticate-navbar/UnauthenticateNavbar";

import img from "../../../assets/images/verifySuccess.jpg";

const EmailVerificationSuccess = () => {
  return (
    <div className="h-screen ">
      <UnauthenticateNavbar />
      <div className="min-h-3/4 container mt-12 flex items-stretch gap-3">
        <div className="hidden xl:block flex-1">
          <img src={img} className="object-fit-cover w-full" alt="" />
        </div>
        <div className="flex-1 flex items-center justify-center h-full my-auto">
          <div className="w-full">
            <div className="my-10 w-full bg-green-300 rounded-md p-6">
              <h2 className="h2 mb-4">
                Your Email has been Verified successfully
              </h2>
            </div>
            <p>Click on the button below to login</p>
            <Link to="/login-bussiness">
              <button type="button" className="btn block btn-success">
                Login
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationSuccess;
