import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Save } from "@mui/icons-material";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editPortalProfile } from "../../services/portalServices";
import { toast } from "react-toastify";
import { verifyUser } from "../../services/authService";
import { setClienData } from "../../redux/Slices/Auth";

const EditUserDetailsForm = ({ setShowEditForm }) => {
  const { clientData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const fetchUserDetails = async () => {
    try {
      const response = await verifyUser(dispatch);
      dispatch(setClienData(response.data.clientData));
    } catch (err) {
      toast.error(err.message);
    }
  };
  const formik = useFormik({
    initialValues: {
      ...clientData,
    },
    onSubmit: async (values, formikHelper) => {
      delete values?.password;
      delete values?.status;
      const res = await editPortalProfile(values);
      if (res.success) {
        formikHelper.resetForm();
        setShowEditForm(false);
        fetchUserDetails();
        toast.success(res.message);
      } else {
        toast.error(res.data?.message);
      }
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      email: Yup.string().required("Required").email("Invalid email"),
      phone: Yup.string()
        .required("Required")
        .min(7, "Phone too short")
        .max(15, "Phone too long"),
      institution: Yup.string().required("Required"),
    }),
  });
  return (
    <Fade cascade>
      {formik.isSubmitting ? (
        <div className="d-flex align-items-center justify-content-center py-72">
          <Spinner animation="grow" variant="success" />
        </div>
      ) : (
        <form onSubmit={formik.handleSubmit} className="row g-3 mt-5">
          <div className="col-12">
            <h4 className="h4">Update Your Details</h4>
          </div>
          <div className="col-12">
            <label htmlFor="firstName" className="form-label">
              First Name
              <sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              id="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              className="form-control w-100"
              placeholder="First Name"
            />
            {formik.errors?.firstName && (
              <p className="text-xs text-danger">{formik.errors?.firstName}</p>
            )}
          </div>
          <div className="col-12">
            <label htmlFor="middleName" className="form-label">
              Middle Name
            </label>
            <input
              type="text"
              id="middleName"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              className="form-control w-100"
              placeholder="Middle Name"
            />
          </div>
          <div className="col-12">
            <label htmlFor="lastName" className="form-label">
              Last Name
              <sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              className="form-control w-100"
              placeholder="Last Name"
            />
            {formik.errors?.lastName && (
              <p className="text-xs text-danger">{formik.errors?.lastName}</p>
            )}
          </div>
          <div className="col-12">
            <label
              htmlFor="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="form-label"
            >
              Email
              <sup className="text-danger">*</sup>
            </label>
            <input
              type="email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="form-control w-100"
              placeholder="Email"
            />
            {formik.errors?.email && (
              <p className="text-xs text-danger">{formik.errors?.email}</p>
            )}
          </div>
          <div className="col-12">
            <label htmlFor="phone" className="form-label">
              Telephone Number
              <sup className="text-danger">*</sup>
            </label>
            <input
              type="tel"
              id="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              className="form-control w-100"
              placeholder="Phone Number"
            />
            {formik.errors?.phone && (
              <p className="text-xs text-danger">{formik.errors?.phone}</p>
            )}
          </div>
          <div className="col-12">
            <button
              type="submit"
              className="btn btn-success d-flex align-items-center justify-content-center"
            >
              <Save sx={{ mr: 2 }} /> Update
            </button>
          </div>
        </form>
      )}
    </Fade>
  );
};

export default EditUserDetailsForm;
