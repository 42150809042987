import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { resentPasswordResetLink } from "../../../services/bussinessServices";
import UnauthenticateNavbar from "../../../components/unauthenticate-navbar/UnauthenticateNavbar";
import forgotpass from "../../../assets/images/forgotpass.jpg";
import { useFormik } from "formik";
import * as Yup from "yup";

const ResendEmail = () => {
  const [message, setMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      const data = await resentPasswordResetLink(values);
      if (data.success) {
        toast.success(data.message);
        setMessage(data.message);
      } else {
        toast.error(data.message);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
    }),
  });

  return (
    <div className="h-screen ">
      <UnauthenticateNavbar />
      <div className="min-h-3/4 container mt-12 flex items-stretch gap-3">
        <div className="hidden xl:block flex-1">
          <img src={forgotpass} className="object-fit-cover w-full" alt="" />
        </div>
        <div className="flex-1 flex items-center justify-center h-full my-auto">
          <div className="w-full">
            {message ? (
              <div className="my-10 w-full bg-green-300 rounded-md p-6">
                <h2 className="text-xl ">{message}</h2>
              </div>
            ) : (
              ""
            )}
            <h2 className="h2 mb-4">Resend Email Verification Token</h2>
            <form
              className="flex flex-col gap-3"
              onSubmit={formik.handleSubmit}
            >
              <div className="form-group">
                <label className="block form-label">Email addrees</label>
                <input
                  id="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  type="text"
                  placeholder="Enter your email address"
                  className="form-control w-fit"
                />
                {formik.errors.email && (
                  <small className="block text-danger texts-sm">
                    {formik.errors.email}
                  </small>
                )}
              </div>
              <button
                disabled={formik.isSubmitting}
                type="submit"
                className="btn block btn-success"
              >
                {formik.isSubmitting ? "Sending... link" : "Send reset link"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResendEmail;
