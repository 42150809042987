import { configureStore } from '@reduxjs/toolkit';
import authReducer from './Slices/Auth';
import clientReducer from './Slices/ClientSlice'
import folderProjectReducer from './Slices/folderProjectSlice';
import systemSlice from './Slices/SystemSlice';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		client: clientReducer,
		folderProject: folderProjectReducer,
		system: systemSlice
    },
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});