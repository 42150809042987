import React from "react";
import logo from "../../assets/icons/logo.png";
import { Link } from "react-router-dom";
import { topSideMenuLinks } from "../../constants/routes";

const SideMenu = () => {
  return (
    <div className="hidden xl:flex flex-col bg-gray-50 w-fit">
      {/* logo */}
      <Link to="/dashboard" className="p-2 block">
        <img className="h-24 w-full object-scale-down" src={logo} alt="GPPA" />
      </Link>
      {/* top */}
      <div className="w-full flex-1 flex flex-col py-3">
        {topSideMenuLinks.map((linkItem) => (
          <Link
            key={linkItem.id}
            to={linkItem.path}
            className="flex items-center text-gray-700 text-decoration-none  text-2xl p-2 hover:text-gray-500"
          >
            {linkItem.icon} <span className="ml-2">{linkItem.text}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SideMenu;
