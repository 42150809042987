import { createSlice } from "@reduxjs/toolkit";

const folderProjectSlice = createSlice({
  name: "folderProject",
  initialState: {
    isLoading: false,
    yearFolders: null,
    categoryFolders: null,
    categoryDocumentsAndFolders: null,
    projectDocumentsAndFolders: null,
    projectFolderChildren: null,
    lots: null,
    awarded: null
  },
  reducers: {
    setFolderProjectIsLoading: (state) => {
      state.isLoading = true;
    },
    resetFolderProjectIsLoading: (state) => {
      state.isLoading = false;
    },
    setYearFolders: (state, action) => {
      state.yearFolders = action.payload;
    },
    resetYearFolders: (state) => {
      state.yearFolders = null;
    },
    setCategoryFolders: (state, action) => {
      state.categoryFolders = action.payload;
    },
    resetCategoryFolders: (state) => {
      state.categoryFolders = null;
    },
    setCategoryDocumentsAndFolders: (state, action) => {
      state.categoryDocumentsAndFolders = action.payload;
    },
    resetCategoryDocumentsAndFolders: (state) => {
      state.categoryDocumentsAndFolders = null;
    },
    setProjectDocumentsAndFolders: (state, action) => {
      state.projectDocumentsAndFolders = action.payload;
    },
    resetProjectDocumentsAndFolders: (state) => {
      state.projectDocumentsAndFolders = null;
    },
    setProjectFolderChildren: (state, action) => {
      state.projectFolderChildren = action.payload;
    },
    resetProjectFolderChildren: (state) => {
      state.projectFolderChildren = null;
    },
    setLots: (state, action) => {
      state.lots = action.payload;
    },
    resetLots: (state) => {
      state.lots = null;
    },
    setAwarded: (state, action) => {
      state.awarded = action.payload;
    },
    resetAwarded: (state) => {
      state.awarded = null;
    },
  },
});

export const {
  setFolderProjectIsLoading,
  resetFolderProjectIsLoading,
  setYearFolders,
  resetYearFolders,
  setCategoryDocumentsAndFolders,
  resetCategoryDocumentsAndFolders,
  setProjectFolderChildren,
  resetProjectFolderChildren,
  setDocumentProjectFolders,
  resetDocumentProjectFolders,
  setCategoryFolders,
  resetCategoryFolders,
  setProjectDocumentsAndFolders,
  resetProjectDocumentsAndFolders,
  setLots,
  resetLots,
  setAwarded,
  resetAwarded
} = folderProjectSlice.actions;

export default folderProjectSlice.reducer;
