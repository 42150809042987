import axios from "axios";

const axiosConfig = axios.create({
  // baseURL: "http://gppa-con.zigtech.net/api/v1",
  // baseURL: "http://localhost:9000/api/v1",
  baseURL: "https://api.gppa.gm/api/v1",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosConfig.interceptors.request.use(function (config) {
  let token = localStorage.getItem("businessToken");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});
axiosConfig.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosConfig;
