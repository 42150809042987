import React from "react";
import { Card } from "react-bootstrap";

const ServerErrorComponent = () => {
  return (
    <div className="h-screen w-screen flex items-center justify-center">
      <Card className="flex flex-col gap-3" style={{width: 500}}>
        <Card.Body>
          <h2 className="font-bold flex items-end gap-2 text-center">
            <span className="text-bg-gray-400">500</span> <span className="text-pink-800">Server Error</span>
          </h2>
          <p>Oops, something went wrong</p>

          <p>
          Check your internet and try to refresh this page or feel free to
            contact us if the problem persist
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ServerErrorComponent;
