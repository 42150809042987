import React, { useState } from "react";
import logo from "../../../assets/icons/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { FaBars, FaClockRotateLeft, FaEnvelope, FaUser } from "react-icons/fa6";
import UserDetailsCanvas from "../../../pages/layout/UserDetailsCanvas";

const MobileNavbar = ({ showUserDetailsCanvas, setShowUserDetailsCanvas }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center justify-between gap-2 xl:hidden p-1">
      <Link to="/dashboard" className="p-2 block">
        <img className="h-12 w-full object-scale-down" src={logo} alt="GPPA" />
      </Link>

      <div className="flex items-center gap-2">
        <button
          onClick={() => navigate("/recent-uploads")}
          className="bg-transparent outline-none border-none p-1"
        >
          <FaClockRotateLeft className="text-2xl cursor-pointer  hover:text-gray-600" />
        </button>
        <button
          onClick={() => navigate("/client-message")}
          className="bg-transparent outline-none border-none p-1"
        >
          <FaEnvelope className="text-3xl cursor-pointer  hover:text-gray-600" />
        </button>
        <button
          onClick={() => setShowUserDetailsCanvas(true)}
          className="bg-transparent outline-none border-none p-1"
        >
          <FaUser className="text-2xl" />
        </button>
      </div>

      {/* canvas */}
      <UserDetailsCanvas
        isOpen={showUserDetailsCanvas}
        setIsOpen={setShowUserDetailsCanvas}
      />
    </div>
  );
};

export default MobileNavbar;
