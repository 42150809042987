import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router";
import { verifyBussiness } from "../services/bussinessServices";
import Loading from "./backdrop/Loading";
import { logout } from "../redux/Slices/Auth";
import { toast } from "react-toastify";

export const RequireBusinessAuth = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response = await verifyBussiness(dispatch);
        setLoading(false);

        console.log("response", response)

        //Check if the token is valid
        if (!response.data.success) {
          toast.error("Invalid token")
          dispatch(logout())
          return
        }

        if (response.tokenExpired) {
          toast.error("Token has expired")
          dispatch(logout())
          return;
        }

      } catch (error) {
        setLoading(false);
        toast.error(error.message)
        dispatch(logout())
        return <Navigate to="/" />;
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loading />;
  }
  // const token = localStorage.getItem("businessToken");
  // if (!token) {
  //   return <Navigate to="/" />;
  // }

  return children;
};
