import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { getRecentMessages } from "../../services/portalServices";
import { FaEnvelope } from "react-icons/fa6";

const LayoutHeader = ({
  setShowUserDetailsCanvas,
  showDropdown,
  setShowDropdown,
}) => {
  const { clientData } = useSelector((state) => state.auth);
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getRecentMessages(setMessages);
  }, []);

  return (
    <header className="hidden bg-white xl:flex items-center justify-between p-6 ">
      {/* left */}
      <div>
        <div></div>
      </div>
      {/* right */}
      <div className="flex items-center">
        <div className="font-monospace">
          Welcome{" "}
          <b>
            {clientData?.firstName} {clientData?.middleName}{" "}
            {clientData?.lastName}
          </b>
          , <b>{clientData.institution}</b>.
        </div>

        <div class="relative inline-block text-left px-4">
          <div>
            <div
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
              onClick={() => setShowDropdown(!showDropdown)}
            >
              <FaEnvelope
                className="text-4xl cursor-pointer mx-2 hover:text-gray-600"
                // onClick={() => navigate("/client-message")}
              />
            </div>
          </div>

          <div
            class={`absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${
              showDropdown ? "block" : "hidden"
            }`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="menu-button"
            tabindex="-1"
          >
            <div class="py-1" role="none">
              <div
                className="cursor-pointer"
                onClick={() => setShowMessages(!showMessages)}
              >
                <span className="text-gray-700 block px-4 py-2 text-sm">
                  {" "}
                  3 Recent Messages{" "}
                  <svg
                    class="-mr-1 h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </div>
              <div class={` ${showMessages ? "block" : "hidden"}`}>
                {messages.length >= 1 &&
                  messages?.map((message, index) => (
                    <Link
                      to={`/client-message/${message.id}`}
                      state="recent"
                      class="text-gray-700 block px-5 py-2 text-sm no-underline"
                      role="menuitem"
                      tabindex="-1"
                      id="menu-item-0"
                      onClick={() => {
                        setShowDropdown(false);
                        setShowMessages(false);
                      }}
                    >
                      {index + 1}. {message.title}
                    </Link>
                  ))}
              </div>
              <Link
                to="/client-message"
                state="all"
                class="text-gray-700 block px-4 py-2 text-sm no-underline"
                role="menuitem"
                tabindex="-1"
                id="menu-item-1"
                onClick={() => setShowDropdown(false)}
              >
                All messages
              </Link>
            </div>
          </div>
        </div>

        <button
          className="h-9 w-9 text-sm xl:text-lg outline-none border border-black rounded-full flex items-center justify-center"
          onClick={() => setShowUserDetailsCanvas(true)}
        >
          {(clientData?.firstName ? clientData?.firstName[0] : "") +
            (clientData?.lastName ? clientData?.lastName[0] : "")}
        </button>
      </div>
    </header>
  );
};

export default LayoutHeader;
