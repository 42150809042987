import { useEffect, useState } from "react";
import {
  getAllBusinessRegistrations,
  getTariffs,
} from "../../services/bussinessServices";

import TablePagination from "@mui/material/TablePagination";

import moment from "moment";
import BackBtn from "../../components/backButton/BackBtn";
import Loading from "../../components/backdrop/Loading";
import "../tariffPage/tariffpage.scss";
import ViewBusinessModal from "./ViewBusinessModal";
import "tippy.js/dist/tippy.css";
import Select from "react-select";

export const BusinessRegisteration = () => {
  const [business, setBusiness] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [viewBusinessId, setViewBusinessId] = useState();
  const [toggleModal, setToggleModal] = useState();
  const [selectedNatureOfBusiness, setSelectedNatureOfBusiness] = useState([]);
  const [tariffOptions, setTariffOptions] = useState([]);

  const getTariffOptions = async () => {
    try {
      const tariffs = await getTariffs();
      const options = tariffs?.data?.map((tariff) => ({
        value: tariff.id,
        label: tariff.name,
      }));
      setTariffOptions(options);
    } catch (error) {
      console.error("Error fetching tariff options:", error);
    }
  };

  const get_tariffs = async () => {
    try {
      setLoading(true);
      const resp = await getAllBusinessRegistrations();
      setLoading(false);
      setBusiness(resp);
      console.log(business)
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    get_tariffs();
    getTariffOptions();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setPage(0);
  };

  const handleNatureOfBusinessChange = (selectedOptions) => {
    setSelectedNatureOfBusiness(selectedOptions);
    setPage(0);
  };

  const filteredBusiness = business?.filter(
    (document) =>
      document.nameOfBusiness?.toLowerCase()?.includes(search?.toLowerCase()) &&
      selectedNatureOfBusiness.every((option) =>
        document.natureOfBusiness
          ?.toLowerCase()
          ?.includes(option.label.toLowerCase())
      )
  );

  const handleViewBusiness = (id) => {
    setViewBusinessId(id);
    setToggleModal(!toggleModal);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="tariffs">
      <div className="header">
        <BackBtn />
        <h2 className="h2 mb-3">Business Registration</h2>
        <p
          style={{
            color: "white",
          }}
        >
          Go
        </p>
      </div>
      <div className="table-container">
        <div className="search-container">
          <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
            <input
              type="search"
              placeholder="Business Registration"
              value={search}
              onChange={handleSearch}
            />
            <div style={{ width: "400px" }}>
              <Select
                isMulti
                placeholder="Select Nature Of Business"
                value={selectedNatureOfBusiness}
                options={tariffOptions}
                onChange={handleNatureOfBusinessChange}
              />
            </div>
          </div>
        </div>
        <table className="tariftable">
          <thead>
            <tr>
              <th>Name of Business</th>
              {/* <th>email</th> */}
              <th>Phone Number</th>
              <th>Address</th>
              <th>country</th>
              <th>city</th>
              <th>Nature of Business</th>
              <th>business Status</th>
              <th>Created </th>
              {/* <th>Amount</th> */}
              {/* <th>actions</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredBusiness.length === 0 ? (
              <div
                style={{
                  fontSize: "20px",
                  padding: "15px 5px",
                  textAlign: "center",
                  margin: "0 auto",
                  fontWeight: "550px",
                }}
              >
                Business Not found!!!
              </div>
            ) : (
              (rowsPerPage > 0
                ? filteredBusiness.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredBusiness
              ).map((business) => (
                <tr key={business.id}>
                  <td>{business.nameOfBusiness || "N/A"}</td>
                  {/* <td>{business.email|| "N/A"}</td> */}
                  <td>{business.phoneNumber || "N/A"}</td>
                  <td>{business.address1 || "N/A"}</td>
                  <td>{business.country || "N/A"}</td>
                  <td>{business.city || "N/A"}</td>
                  <td>
                    {business?.natureOfBusiness
                      ? Array.isArray(business.natureOfBusiness)
                        ? business.natureOfBusiness
                            .map((value) => value.replace(/["\[\]]/g, ""))
                            .join(", ")
                        : business.natureOfBusiness.replace(/["\[\]]/g, "")
                      : "N/A"}
                  </td>
                  <td>
                    {business?.businessStatus === "pending" ? (
                      <span className="badge bg-danger text-white">
                        Pending
                      </span>
                    ) : (
                      <span
                        className="badge  text-white"
                        style={{ backgroundColor: "green" }}
                      >
                        Registered
                      </span>
                    )}
                  </td>
                  <td>
                    {moment(business?.createdAt).format("YYYY-MM-DD") || "N/A"}
                  </td>
                  {/* <td>{business?.totalAmount || "N/A"}</td>
                    <td>
                      <div onClick={()=> handleViewBusiness(business.id)}>
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      </div>
                    </td> */}
                </tr>
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan={11}
                className="text-center"
                style={{ backgroundColor: "#ddd" }}
              >
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={filteredBusiness?.length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  component="div"
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <ViewBusinessModal
        show={toggleModal}
        close={handleViewBusiness}
        id={viewBusinessId}
      />
    </div>
  );
};
