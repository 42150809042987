import React from "react";
import Navbar from "../../components/navbar/Navbar";
import { FileIcon } from "../../assets/icons/icons";
import "./ViewDocumentCopyList.scss";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { getSingleFolder } from "../../services/docService";
import Loading from "../../components/backdrop/Loading";
import { useNavigate } from "react-router-dom";
import { UploadFileModal } from "../../components/uploadFileModal/UploadFileModal";
import { Link } from "react-router-dom";

export const ViewDocumentCopyList = ({ user }) => {
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);

  const { id } = useParams();

  const fetchDocs = async () => {
    try {
      setLoading(true);
      const response = await getSingleFolder(id);
      setFiles(response.data.documents);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchDocs();
  }, []);
  const handleBack = () => {
    navigate(-1);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  if (loading) {
    <Loading />;
  }

  return (
    <div className="file-manager">
      {/* <Navbar user={user} /> */}

      <div className="sub-nav">
        <div
          className="back-btn"
          onClick={() => handleBack()}
          style={{ marginLeft: "40px" }}
        >
          back
        </div>

        <div className="upload-btn" onClick={handleToggle}>
          upload
        </div>
      </div>

      <div className="file-manager__content">
        {!files ? (
          <div div className="content-title">
            No Files Available
          </div>
        ) : (
          <div div className="content-title">
            File
          </div>
        )}

        <div className="file-manager__content--list">
          {files?.map((file) => {
            return (
              <Link to={`/doc-details/${file.id}`} className="file">
                <div className="file__icon">
                  <FileIcon />
                </div>
                <p className="file__name">{file?.filename}</p>
              </Link>
            );
          })}
        </div>
      </div>
      <UploadFileModal
        show={toggle}
        close={handleToggle}
        folderId={id}
        user={user}
        setFiles={setFiles}
      />
    </div>
  );
};
