import React from "react";
import logo from "../../assets/icons/logo.png";

import { Link } from "react-router-dom";
const UnauthenticateNavbar = () => {
  const path = window.location.pathname;

  return (
    <div className="bg-white flex items-center justify-between p-2">
      <div className="logo">
        <Link to="/" className="text-decoration-none">
          <img src={logo} alt="" className="h-10 xl:h-16 object-scale-down" />
        </Link>
      </div>
      <div className="flex items-center gap-3 list-none">
        <Link
          className="text-xs xl:text-lg text-black text-decoration-none hover:text-gray-500"
          to="/"
        >
          Home
        </Link>

        <Link
          className={`text-xs xl:text-lg text-black text-decoration-none hover:text-gray-500 ${
            path.endsWith("/login-bussiness") &&
            "bg-blue-600 text-white py-1 px-3 rounded-md"
          }`}
          to="/login-bussiness"
        >
          Business
        </Link>

        <Link
          className={`text-xs xl:text-lg text-black text-decoration-none hover:text-gray-500 ${
            path.endsWith("/login") &&
            "bg-blue-600 text-white py-1 px-3 rounded-md"
          }`}
          to="/login"
        >
          Procurement
        </Link>
      </div>
    </div>
  );
};

export default UnauthenticateNavbar;
