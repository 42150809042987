import { toast } from "react-toastify";
import http from "../http-common";

export const getDocuments = async () => {
  const data = await http
    .get(`/clientDoc/clientDocuments`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};
//pagination
const token = localStorage.getItem("token");
export const getAllDocs = async (pageNum, limit) => {
  const data = await http
    .get("/clientDoc/clientDocuments", {
      params: {
        page: pageNum,
        limit: limit,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      toast.error(error.message);
    });

  return data;
};

export const getSingleDoc = async (doc_id) => {
  const data = await http
    .get(`/document/${doc_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const readPDF = async (doc_id) => {
  const data = http
    .get(`/document/readDocs/subDocs/${doc_id}`, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      return url;
    })
    .catch((error) => {
      toast.error(error.response.message);
      return error.response;
    });

  return data;
};

export const getFolders = async () => {
  const data = await http
    .get(`/folder`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const getSingleFolder = async (folder_id) => {
  const data = await http
    .get(`folder/${folder_id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err.response;
    });

  return data;
};

export const addFolder = (data) => {
  http
    .post("/folder", data)
    .then((response) => {})
    .catch((error) => {
      console.error(error);
    });
};

export const uploadFile = (data, token) => {
  return http
    .post("/document", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message);
      throw error;
    });
};

// Create Sub-Folder.

export const createFolder = (document_id, data) => {
  const result = http
    .post(`/subFolder/${document_id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response;
    });

  return result;
};

export const addFile = (data, token, setProgress) => {
  return http
    .post("/document/uploadDocument", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
      onUploadProgress: (ProgressEvent) => {
        // console.log("ProgressEvent");
        // console.log(Math.round(ProgressEvent.progress * 100));
        const percent = Math.round(ProgressEvent.progress * 100);
        // console.log((ProgressEvent.loaded / ProgressEvent.total) * 100);
        // const percent = Math.round(
        //   (ProgressEvent.loaded / ProgressEvent.total) * 100
        // );
        // console.log("first");
        // console.log(percent);
        // console.log(typeof setProgress);
        setProgress(percent);
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error.message);
      throw error;
    });
};

export const getSubFolders = async () => {
  const data = await http
    .get("/subFolder")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast(err.response.message);
    });

  return data;
};

export const getSingleSubFolders = async (folder_id, setFolderName) => {
  const data = await http
    .get(`/subFolder/${folder_id}`)
    .then((response) => {
      setFolderName(response?.data?.data?.subFolderName);
      return;
      // return response?.data?.data;
    })
    .catch((err) => {
      toast(err.response.message);
    });

  return data;
};

export const updateSubFolderName = async (folder_id, data) => {
  const result = await http
    .put(`/subFolder/${folder_id}`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      toast(err.response.message);
    });

  return result;
};
