import http from "../http-common";
import { toast } from "react-toastify";
import {
  CancelPending,
  loginError,
  loginErrorVerify,
  loginRequest,
  loginSuccess,
  registerError,
  registerRequest,
  registerSuccess,
} from "../redux/Slices/Auth";
import { useState } from "react";
import { clientLoginSuccess } from "../redux/Slices/ClientSlice";

//login user
export const loginUser = async (user, dispatch, navigate) => {
  dispatch(loginRequest());
  const data = await http
    .post("/clientAuth/login", user)
    .then(async (response) => {
      localStorage.setItem("token", response.data.token);
      const verified = await verifyUser(dispatch);
      if (verified.data.success) {
        navigate("/dashboard");
      }
    })
    .catch((error) => {
      console.log(error);
      toast.error(error.response?.data?.message);
      // dispatch(loginError(error));
    });

  return data;
};

//verify user
export const verifyUser = async (dispatch) => {
  if (!localStorage.getItem("token")) {
    window.location = "/";
  }
  try {
    const response = await http.get("/clientAuth/verify");
    // dispatch(clientLoginSuccess(response.data.clientData))
    return response;
  } catch (error) {
    console.log(error);
    // dispatch(loginErrorVerify(error));
    return error.response.data;
  }
};

//register user
export const register = (submitted, dispatch, navigate) => {
  dispatch(registerRequest());
  http
    .post("/clientAuth/register", submitted)
    .then((res) => {
      if (res.data.success) {
        toast.success("Registration success");
        dispatch(registerSuccess(res.data.data));
        navigate("/login");

        // window.location.reload();
      }
    })
    .catch((error) => {
      toast.error(error.response?.data?.message);
      dispatch(registerError());
    });
};
//is logged in
export const isLogged = async (navigate) => {
  const authToken = localStorage.getItem("token");
  try {
    const response = await http.get("/clientAuth/verify", {
      headers: {
        Authorization: "Bearer " + authToken,
      },
    });
    if (response.data.success) {
      navigate("/dashboard");
    }
  } catch (error) {
    console.log(error);
  }
};
