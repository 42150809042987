import React from "react";
import { Link } from "react-router-dom";

import UnauthenticateNavbar from "../../../components/unauthenticate-navbar/UnauthenticateNavbar";

import img from "../../../assets/images/verifyError.jpg";

const EmailVerificationError = () => {
  return (
    <div className="h-screen ">
      <UnauthenticateNavbar />
      <div className="min-h-3/4 container mt-12 flex items-stretch gap-3">
        <div className="hidden xl:block flex-1">
          <img src={img} className="object-fit-cover w-full" alt="" />
        </div>
        <div className="flex-1 flex items-center justify-center h-full my-auto">
          <div className="w-full">
            <div className="my-10 w-full bg-red-300 rounded-md p-6">
              <h2 className="h2 mb-4">"Your email failed to verified"</h2>
            </div>
            <p>Click on the button below to go back to login</p>
            <Link to="/resend-email-verification-token">
              <button type="button" className="btn block btn-success">
                Try Again
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationError;
